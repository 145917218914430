/* eslint no-console: 0 */
import Vue from 'vue/dist/vue.esm'
import axios from 'axios';
import _ from 'lodash';
import '../../common/fake.css'

document.addEventListener('DOMContentLoaded', () => {

    let formAttributes = JSON.parse(document.getElementById('sign-up-form').dataset.form)
    let formValid = true
    let invalidFieldFocused = false
    let data = _.assign({}, formAttributes)
    _.forEach(_.keys(formAttributes), function (field) {
        data[`${field}_validation`] = null
    });
    data.account_availability_status = null;
    data.account_availability = null;

    let instantCheckAvailability = function (newCompanyName) {
        return axios.get("/sign_up/account_available.json", { params: { account_name: newCompanyName }, headers: { "Accept": "application/json" } })
            .then(({ data }) => {
                if (data.valid) {
                    if (data.available) {
                        form.account_availability_status = 'available';
                        form.account_availability = `You're in luck! The account ${newCompanyName} is available.`;
                    } else {
                        form.account_availability_status = 'not_available';
                        form.account_availability = `Sorry, ${newCompanyName} is already taken.`;
                    }
                } else {
                    form.account_availability_status = 'not_available';
                    form.account_availability = `Sorry, ${newCompanyName} ${data.name_error}.`;
                }
            })
            .catch(function (data) {
                console.error(data);
            });
    };

    let checkAvailability = _.debounce(instantCheckAvailability, 1000);

    const form = new Vue({
        el: "#sign-up-form",
        data: data,
        watch: {
            account_name: function (newCompanyName) {
                this.validate_account_name();

                if (_.isEmpty(newCompanyName)) {
                    this.account_availability_status = null;
                } else {
                    this.account_availability_status = 'checking';
                    this.account_availability = `checking availability of ${newCompanyName}...`;

                    checkAvailability(newCompanyName);
                }
            },
            company_name: function () { this.validate_company_name(); },
            role: function () { this.validate_role(); },
            industry: function () { this.validate_industry(); },
            employees_count: function () { this.validate_employees_count(); },
            country: function () { this.validate_country(); },
            ad_channel: function () { this.validate_ad_channel(); },
            other_ad_channel: function () { this.validate_other_ad_channel(); },
        },
        methods: {
            validatePresenceOf: function (fieldName, errorMessage) {
                if (_.isEmpty(this[fieldName])) {
                    formValid = false;
                    this[`${fieldName}_validation`] = errorMessage;

                    if (!invalidFieldFocused) {
                        document.getElementById(`sign_up_form_${fieldName}`).focus();
                        invalidFieldFocused = true
                    }
                }
            },
            clearValidation: function (fieldName) {
                this[`${fieldName}_validation`] = null;
            },
            validate_company_name: function () {
                this.clearValidation('company_name');
                this.validatePresenceOf("company_name", 'Company name is required.');
            },
            validate_account_name: function () {
                this.clearValidation('account_name');
                this.validatePresenceOf("account_name", 'Account name is required.');
            },
            validate_role: function () {
                this.clearValidation('role');
                this.validatePresenceOf("role", 'Role is required.');
            },
            validate_industry: function () {
                this.clearValidation('industry');
                this.validatePresenceOf("industry", 'Industry is required.');
            },
            validate_employees_count: function () {
                this.clearValidation('employees_count');
                this.validatePresenceOf("employees_count", 'Number of employees is required.');
            },
            validate_country: function () {
                this.clearValidation('country');
                this.validatePresenceOf("country", 'Country is required.');
            },
            validate_ad_channel: function () {
                this.clearValidation('ad_channel');
                this.validatePresenceOf("ad_channel", 'How did you hear about us is required.');
            },
            validate_other_ad_channel: function () {
                this.clearValidation('other_ad_channel');
                if (this.ad_channel === 'other') {
                    this.validatePresenceOf("other_ad_channel", 'Please specify how you heard about us.');
                }
            },
            validate: function () {
                formValid = true;
                invalidFieldFocused = false;

                this.validate_company_name();
                this.validate_account_name();
                this.validate_role();
                this.validate_industry();
                this.validate_employees_count();
                this.validate_country();
                this.validate_ad_channel();
                this.validate_other_ad_channel();

                return formValid;
            },
            onSubmit: function (event) {
                if (this.validate()) {
                    event.target.submit();
                }
            }
        }

    })

    if (!_.isEmpty(form.account_name)) {
        instantCheckAvailability(form.account_name);
    }

})